<template>
  <VButton
    class="DeleteBrandButton"
    data-testid="DeleteBrandButton"
    @click="deleteBrand"
  >
    {{ $t('components.delete_brand_button.delete_brand') }}
  </VButton>
</template>

<script>
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService.js'

export default {
  components: {
    VButton
  },

  props: {
    brandId: {
      type: String,
      required: true
    },

    brandName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    async deleteBrand () {
      this.isLoading = true

      const regex = new RegExp(this.$t('components.delete_brand_button.delete'), 'i')

      try {
        await this.$prompt(this.$t('components.delete_brand_button.prompt_content', { brand: this.brandName }), this.$t('components.delete_brand_button.prompt_title'), {
          inputPattern: regex,
          inputErrorMessage: this.$t('components.delete_brand_button.invalid_passphrase'),
          inputValidator: false
        })

        await BrandApiService.delete(this.brandId)

        this.$notify.success(this.$t('components.delete_brand_button.marked_for_deletion', { brand: this.brandName }))

        this.$emit('delete')
      } catch (err) {
        if (err === 'cancel') {
          return
        }

        this.$displayRequestError(err)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
